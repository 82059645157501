export default class Calculator {
  constructor(ctx) {
    this.oilPrice = ctx.oilPrice;
    this.KWHPrice = ctx.KWHPrice;
    this.months = ctx.months;
    this.KMPerMonth = ctx.KMPerMonth;
    this.KMPerKWH = ctx.KMPerKWH;
    this.KMPerLiter = ctx.KMPerLiter;
  }

  get savings() {
    return this.oPrice - this.ePrice;
  }

  get totalKM() {
    return this.months * this.KMPerMonth;
  }

  get ePrice() {
    return this.totalKM / this.KMPerKWH * this.KWHPrice;
  }

  get oPrice() {
    return this.totalKM / this.KMPerLiter * this.oilPrice;
  }
}