<template>
  <div class="custom-input">
    <label :for="`c-${_uid}`" class="custom-input-label">
      {{ label }}
      <span class="custom-input-hint" v-if="hint" data-toggle="tooltip" :title="hint">
      </span>
    </label>

    <div @click="focusInput" class="input-wrapper" :class="{['input-wrapper--disabled']: $attrs.disabled !== undefined }">
      <input :id="`c-${_uid}`" ref="input" class="input-wrapper__field" :value="value" v-bind="$attrs" @input="input">
      <span class="input-wrapper__append">{{ append }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    props: ['value', 'label', 'append', 'hint'],
    methods: {
      input(event) {
        this.$emit('input', event.target.value);
      },
      focusInput() {
        this.$refs.input.focus();
      }
    },
    mounted() {

    },
  }
</script>
<style lang="scss" scoped>
  .custom-input {
    font-size: 18px;
  }

  .custom-input-label {
    padding: 0 0 0 8px;
    font-size: 16px;
    margin: 0 0 6px 0;
  }

  .input-wrapper__field {
    border: none;
    appearance: none;
    flex: 1;
    background-color: inherit;
    width: 0;
    padding: 0;
    font-weight: 600;
    height: 40px;
    align-self: stretch;
    color: #6c757d;
    &:focus {
      outline: none;
    }
  }

  .input-wrapper {
    padding: 0 1em;
    border-radius: 20px;
    border: 1px solid $input-border-color;
    display: flex;
    align-items: center;

    &--disabled {
      background-color: #eff2f5;
      .input-wrapper__field {
        color: #4f575f;
        opacity: 1;
      }
    }
  }

  .input-wrapper__append {
    font-size: 14px;
    padding: 0 0 0 .4em;
    color: #666;
  }

  .custom-input-hint {
    position: relative;
    top: -2px;
    &::before {
      content: '?';
      border-radius: 50%;
      opacity: .6;
      background-color: #6c757d;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      width: 14px;
      height: 14px;
      color: #ffffff;
      font-size: 10px;
      line-height: 1;
    }
  }
</style>