import 'assets/stylesheets/application.scss';
import Vue from 'vue'
import './filters';
import App from './App.vue'
// import './registerServiceWorker'
import store from './store'
import 'bootstrap';

Vue.config.productionTip = false


new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
