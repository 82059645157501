import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    KWHPrice: 5.0,
    KMPerMonth: 1250,
    oilPrice: 30.4,
    KMPerKWH: 6,
    KMPerLiter: 12,
    model: null,
  },
  mutations: {
    updateField,
    applyOfficialSettings(state) {
      state.oilPrice = 30.8;
      state.KWHPrice = 4.23;
      state.KMPerLiter = 10.98;
      state.KMPerMonth = 15000 / 12;
      state.KMPerKWH = 6.3;
      // state.KMPerKWH = ((state.KMPerLiter / state.oilPrice * state.KWHPrice) * 4).toFixed(2);
    },
    resetSettings(state) {
      state.KWHPrice =  5.0;
      state.KMPerMonth =  1250;
      state.oilPrice =  30.4;
      state.KMPerKWH =  6;
      state.KMPerLiter =  12;
    },
  },
  getters: {
    getField,
  },
  actions: {
  },
  modules: {
  }
})
