<template>
  <div id="app">
    <buyev/>
    <div class="container">
      <div class="page-header">
        <h1 class="page-title"><a href="/">Tesla 電費計算機</a></h1>
        <div class="social-share">
          <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Ftesla-calculator.yfxie.com&width=320&layout=standard&action=like&size=small&height=35&appId=2544212955813395" width="320" height="35" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="card mb-4 mb-lg-0">
            <div class="card-header">
              <span class="card-header-title">輸入</span>
            </div>
            <div class="card-body">
              <div class="form-group">
                <custom-input label="每月平均里程" append="KM" v-model="KMPerMonth" type="number"/>
              </div>

              <div class="row my-4">
                <div class="col-sm-6">
                  <div class="card">
                    <div class="card-header">
                      <span class="card-header-title">電車設定值</span>
                    </div>
                    <div class="card-body">
                      <div class="form-group">
                        <custom-input label="電費" append="元 / 度" v-model="KWHPrice" type="number"/>
                      </div>
                      <div class="form-group">
                        <custom-input hint="每度電5-8公里為 Model3 約略耗能，實際狀況因使用條件、環境而異" label="每度電可跑" append="KM" v-model="KMPerKWH" type="number"/>
                      </div>

                      <div class="form-group">
                        <custom-input label="每公里" disabled="" append="元" :value="(KWHPrice/KMPerKWH).toFixed(2)" type="number"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 mt-3 m-sm-0">
                  <div class="card">
                    <div class="card-header">
                      <span class="card-header-title">油車設定值</span>
                    </div>
                    <div class="card-body">
                      <div class="form-group">
                        <custom-input label="油價" append="元 / 公升" v-model="oilPrice" type="number"/>
                      </div>
                      <div class="form-group">
                        <custom-input label="每公升可跑" append="KM" v-model="KMPerLiter" type="number"/>
                      </div>
                      <div class="form-group">
                        <custom-input label="每公里" disabled="" append="元" :value="(oilPrice/KMPerLiter).toFixed(2)" type="number"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 d-flex flex-column justify-content-between">
          <div ref="resultCard" class="card mb-4 mb-lg-0">
            <div class="card-header">
              <span class="card-header-title">計算結果</span>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="result">
                  <strong class="result-subhead">每月行駛 {{monthResult.totalKM}} 公里</strong>
                  <ul class="result-list">
                    <li class="result-item">
                      <span class="result-item__name">電車費用</span>
                      <span class="result-item__amount">{{ monthResult.ePrice | TWD }}</span>
                    </li>
                    <li class="result-item">
                      <span class="result-item__name">油車費用</span>
                      <span class="result-item__amount">{{ monthResult.oPrice | TWD }}</span>
                    </li>
                    <li v-if="monthResult.savings > 0" class="result-item result-item--savings">
                      <span class="result-item__name">節省燃料開支</span>
                      <span class="result-item__amount">{{ monthResult.savings | TWD }}</span>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="list-group-item">
                <div class="result">
                  <strong class="result-subhead">每年 {{yearResult.totalKM}} 公里</strong>
                  <ul class="result-list">
                    <li class="result-item">
                      <span class="result-item__name">電車費用</span>
                      <span class="result-item__amount">{{ yearResult.ePrice | TWD }}</span>
                    </li>
                    <li class="result-item">
                      <span class="result-item__name">油車費用</span>
                      <span class="result-item__amount">{{ yearResult.oPrice | TWD }}</span>
                    </li>
                    <li v-if="yearResult.savings > 0" class="result-item result-item--savings">
                      <span class="result-item__name">節省燃料開支</span>
                      <span class="result-item__amount">{{ yearResult.savings | TWD }}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div v-show="fiveYearsResult.savings > 0" class="card-body">
              <p class="savings">
                五年共節省
                <span class="d-inline-block">
                <span class="savings__amount" ref="totalSavings">0</span> 燃料開支</span>
              </p>
            </div>
          </div>
          <div class="actions">
<!--            <button class="btn btn-primary btn-block" @click="blur(share, $event)">下載計算結果</button>-->
            <button data-toggle="tooltip" data-placement="top" title="使用 Tesla 官方燃料節省開支的設定值(Model3)" class="btn btn-primary btn-block" @click="blur(applyOfficialSettings, $event)">使用節省開支設定值</button>
            <button class="btn btn-primary btn-block" @click="blur(resetSettings, $event)">重設</button>
          </div>
        </div>
      </div>
      <ul class="disclaimer">
        <li class="disclaimer-item">預設參數使用了較為客觀的設定值。</li>
        <li class="disclaimer-item">僅燃料方面在客觀的條件下電車支出以優於油車許多，更不用說電車省下了油車所需的多項保養費(如：機油、火星塞、來令片...等)。</li>
        <li class="disclaimer-item">關於特斯拉有其它問題可參考 <a href="https://tesla.yfxie.com">Tesla 購車懶人包</a>。</li>
        <li class="disclaimer-item">使用 <a href="https://ts.la/yfxie32571">https://ts.la/yfxie32571</a> 推薦連結購買特斯拉，可獲得 $15,500 購車優惠及 90 天增強版自動輔助駕駛。</li>
      </ul>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapMutations } from 'vuex';
import Dropdown from './components/Dropdown'
import CustomInput from "./components/CustomInput";
import Buyev from "./components/Buyev";
import { mapFields } from 'vuex-map-fields';
import Calculator from './Calculator';
import { CountUp } from 'countup.js';
import html2canvas from 'html2canvas';

export default {
  name: 'app',
  data() {
    return {
      modelOptions: [{ label: 'Model3', value: 1 }]
    };
  },
  components: {
    Dropdown,
    CustomInput,
    Buyev,
  },
  methods: {
    ...mapMutations(['applyOfficialSettings', 'resetSettings']),
    blur(f, e) {
      f();
      e.target.blur();
    },
    share() {
      const app = document.querySelector('#app');
      html2canvas(app, {
        windowWidth: app.scrollWidth,
        windowHeight: app.scrollHeight,
        ignoreElements: element => {
          return $(element).is('.disclaimer,.actions');
        },
      }).then(canvas => {
        const link = document.createElement('a');
        link.download = '我的燃料節省開支.png';
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  },
  computed: {
    ...mapFields(['KWHPrice', 'KMPerMonth', 'model', 'oilPrice', 'KMPerKWH',
      'KMPerLiter']),
    monthResult() {
      return new Calculator({ months: 1, ...this.ctx });
    },
    yearResult() {
      return new Calculator({ months: 12, ...this.ctx });
    },
    fiveYearsResult() {
      return new Calculator({ months: 60, ...this.ctx });
    },
    ctx() {
      return { oilPrice: this.oilPrice,
        KWHPrice: this.KWHPrice,
        KMPerMonth: this.KMPerMonth,
        KMPerKWH: this.KMPerKWH,
        KMPerLiter: this.KMPerLiter,
      };
    },
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
    this.counter = new CountUp(this.$refs.totalSavings, this.fiveYearsResult.savings, { prefix: '$', duration: 1.2 });
    this.counter.start();
  },
  watch: {
    fiveYearsResult(result) {
      this.counter.options.duration = .7;
      if (typeof result.savings === 'number' && Math.abs(result.savings) !== Infinity) {

        this.counter.update(result.savings);
      }

    },
  }
}
</script>

<style lang="scss" scoped>
  #app {
    padding: 0 8px 24px 8px;
    background-color: inherit;
    @include media-breakpoint-up(sm) {
      padding: 0 8px 48px 8px;
    }
  }

  .page-header {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 .2em 0;
    @include media-breakpoint-up(sm) {
      margin: 0 0 .4em 0;
    }
    @include media-breakpoint-up(md) {
      display: flex;
    }
    a {
      @include hover-focus-active {
        text-decoration: none;
      }
    }
  }

  .page-title {
    font-size: inherit;
    font-weight: inherit;
    margin: 0 0 6px 0;
  }

  .disclaimer {
    margin: 2em 0 0 0;
    font-size: 14px;
    color: rgba(0,0,0, .3);
    padding-left: 1.5em;
  }

  .disclaimer-item {
    & + & {
      margin: 2px 0 0 0;
    }
  }

  .result-subhead {
    margin: 0 0 .3em 0;
    display: block;
    color: #333;
    font-weight: normal;
    font-size: 18px;
  }

  .result-list {
    padding: 0;
    list-style: disc;
    margin: 0 0 .4em 0;
  }

  .result-item {
    & + & {
      margin-top: .12em;
    }
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    color: #666;
    &--savings {
      font-weight: 500;
      color: $green;
    }
  }

  .savings {
    color: $green;
    text-align: center;
    &__amount {
      font-size: 1.4em;
      font-feature-settings: tnum;
    }
  }

  .result {
    margin: .4em .4em;
  }

  .actions {
    display: grid;
    row-gap: 6px;

    .btn {
      line-height: 1.6;
      font-size: 16px;
    }
  }

  .social-share {
    line-height: 1;
    @include media-breakpoint-up(md) {
      margin: 4px 0 0 12px;
    }
  }
</style>
