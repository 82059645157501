// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/icon-confetti.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".buyev[data-v-41d1feb6]{text-align:center;margin:0 0 24px 0;padding:8px 0;border-bottom:1px solid rgba(0,0,0,.1);background-color:#fff}@media (min-width:576px){.buyev[data-v-41d1feb6]{margin:0 0 48px 0}}.buyev-content[data-v-41d1feb6]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;font-size:16px;font-weight:500}.icon-confetti[data-v-41d1feb6]{display:block;width:18px;height:18px;margin:0 8px 0 0;background:50%/contain no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}", ""]);
// Exports
module.exports = exports;
