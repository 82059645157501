<template>
  <div class="dropdown-wrapper">
    <select v-model="selectedOption">
      <option class="dropdown__blank-option" disabled :value="null">{{blankOption}}</option>
      <option v-for="option in options" :key="option.label" :value="option" v-html="option.label"></option>
    </select>
    <div class="dropdown-label" v-html="selectedOption ? selectedOption.label : blankOption"></div>
  </div>
</template>
<script>
  export default {
    props: ['value', 'options', 'blankOption'],
    computed: {
      selectedOption: {
        get() {
          return this.options.find(o => o === this.value) || null;
        },
        set(option) {
          this.$emit('input', option);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .dropdown-wrapper {
    position: relative;
    border-radius: 100px;
    text-align: center;
    background: rgba(255,255,255,.9) url(../assets/ic-sidebar-arrow.svg) right 24px center/18px auto no-repeat;

    height: 40px;
    line-height: 40px;

    @include desktop {
      height: 44px;
      line-height: 44px;
    }
  }

  select {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    font-size: inherit;
  }

  .dropdown-label /deep/ .scooter-name {
    font-family: 'Graphik', sans-serif;
    font-weight: 400;
    letter-spacing: -0.02em;
  }

  .dropdown-label /deep/ .series-name {
    font-family: 'Graphik', sans-serif;
    font-weight: 700; /* bold */
    letter-spacing: 0;
  }

  .dropdown-label /deep/ .city-note {
    font-size: .75em;
    position: relative;
    top: -.35em
  }

  .dropdown__blank-option {
    pointer-events: none;
  }
</style>
