// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/ic-sidebar-arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".dropdown-wrapper[data-v-4ee9aec5]{position:relative;border-radius:100px;text-align:center;background:hsla(0,0%,100%,.9) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") right 24px center/18px auto no-repeat;height:40px;line-height:40px}@media (min-width:543px){.dropdown-wrapper[data-v-4ee9aec5]{height:44px;line-height:44px}}select[data-v-4ee9aec5]{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;font-size:inherit}.dropdown-label[data-v-4ee9aec5] .scooter-name{font-family:Graphik,sans-serif;font-weight:400;letter-spacing:-.02em}.dropdown-label[data-v-4ee9aec5] .series-name{font-family:Graphik,sans-serif;font-weight:700;letter-spacing:0}.dropdown-label[data-v-4ee9aec5] .city-note{font-size:.75em;position:relative;top:-.35em}.dropdown__blank-option[data-v-4ee9aec5]{pointer-events:none}", ""]);
// Exports
module.exports = exports;
